<template>
  <div ref="rootNode">
    <div />
  </div>
</template>

<script>

import * as THREE from 'three';
import { emits, inject, onMounted, onUnmounted, ref, toRaw, watch } from 'vue';
import Camera from './Camera.vue';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { OBJExporter } from 'three/addons/exporters/OBJExporter.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { decode } from 'fast-png';
//import jpeg from 'jpeg-js';
import * as landscapeShader from './LandscapeShader.js'

export default {
    name: 'Terrain',
        "components": {
        },
        "props": {
            params: {
                type: Object,
                required: true,
            },
            surfaceMesh: {
                type: Object,
                required: true
            }
        },
        "emits": [],
        setup(props, { emit }) {

            const scene = inject('scene'),
            axios = inject('Axios'),
            camera = inject('camera'),
            keyPress = inject('keyPress'),
            renderer = inject('renderer'),
            rootNode = ref(null),
            textureMaps = [],
            buffers = {},
            scale = ref(1),
            worker = new Worker(new URL('./terrain-worker.js', import.meta.url)),
            textureLoader = new THREE.TextureLoader(),
            trackPosition = new THREE.Vector3(),
            activeSegments = ref([]),
            setCameraPosition = ref(true),
            terrainNode = ref(new THREE.Group()),
            terrainData = ref({}),
            terrainMaterial = ref({}),
            currentGrid = ref([]), // which grid the camera is in
            originGrid = ref([0,0]), // which grid is the origin
            cacheMap = ref({}),
            debounceKey = ref({}),
            activeLights = {},
            heightMaps = ref({}),
            decimateMaps = ref({}),
            normalMaps = ref({}),
            geometryQueue = {},
            mergePairs = {}, // pre-calculated lod neighbor segment vertex pairs for merging normals
            inUpdate = ref(false),
            updateDelta = ref(125), // how far we the camera move without updating
            loader = new THREE.ObjectLoader(),
            /*
             * Overview ! So how is this map architected ?
             * We have segments in json that contain the data of the map, here is a segment sample
             *   {
             *     "terrain": {
             *       "scale": 1,
             *       "lods": [256, 128, 64, 32, 16, 8],
             *       "position": [0,0,0],
             *       "rotation": [90,0,0],
             *       "grids": [{
             *         "id": [-3,3],
             *         "position": [0,0,0],
             *         "rotation": [0,0,0],
             *         "alphablack": false,
             *         "heightmap": "public/terrain/tile-00001.png"
             *       },
             *
             * So each grid will have data on a high level with a grid x,y id, think GPS grid reference
             * The map then behaves as a window that runs over these grids and can span multiple grids
             * The number of grids the map loads is managed by 'mapSize' and 'currentGrid' tracks the
             * grid that the camera is presently in.
             *
             * Within a map then there are multiple mesh segments, that make
             *
             *
             */
            loadTerrain = () => {

                axios.get(
                  //`${process.env.VUE_APP_SERVER_URI}public/terrain.json`,
                  `https://www.intradeep.com/public/terrain.json`,
                  {

                      "headers": {
                      }

                  }

                ).
                  then(async (response) => {

                      if (response.status === 200) {

                          const n = response.data;
                          terrainData.value = n.terrain;

                          calculateMergePairs();
                          await loadTextures();
                          loadMaps();

                      }

                }).
                catch((error) => {console.log(error)});

            },
            loadMaps = async () => {

                // build macromaps
                buildMacroMap();

                // load the height and normal data in worker  
                for (var i=0; i< terrainData.value.grids.length; i++) {

                    const offset = terrainData.value.heightOffset;
                    const gridX = terrainData.value.grids[i].id[0];
                    const gridY = terrainData.value.grids[i].id[1];
                    const z = terrainData.value.heightScale > 0 ?
                      terrainData.value.heightScale: 1.0;

                    let {width,height,data} = await loadHeightMap(terrainData.value.grids[i].heightmap);

                    const scale = {
                      x: 1,
                      y: 1,
                      z
                    }

                    worker.postMessage({
                        // action: 'getHeightNormalMap', // enable this to load normals from image (high mem usage)
                        action: 'getHeightMap',
                        request: {
                          width,
                          height,
                          data,
                          scale,
                          gridX,
                          gridY,
                          offset:0
                        }
                    });

                }

            },
            buildMap = async () => {

                console.log("BUILD MAP")

                const spg = terrainData.value.gridSize / terrainData.value.lods[0];

                scale.value = terrainData.value.scale;

                const mapsegs = {};

                for (let k = 0; k < terrainNode.value.children.length; k++) {

                    const s = terrainNode.value.children[k];
                    //console.log("s?.userData?.terrainInfo?.coords");
                    //console.log(s?.userData?.terrainInfo?.coords);

                    mapsegs[s?.userData?.terrainInfo?.coords] = true;

                }

                let x, y, z;

                const baseX = currentGrid.value[0];
                const baseY = currentGrid.value[1];

                const halfMap = (terrainData.value.gridSize * terrainData.value.mapSize/2) - terrainData.value.gridSize/spg;
                const centermap = new THREE.Vector3(halfMap, halfMap, terrainData.value.heightOffset);

                for (let i = 0; i < terrainData.value.mapSize * spg; i++) {

                    x = (i * terrainData.value.lods[0] - terrainData.value.lods[0]/2) * terrainData.value.scale;

                    for (let j = 0; j < terrainData.value.mapSize * spg; j++) {

                        y = (j * terrainData.value.lods[0] - terrainData.value.lods[0]/2) * terrainData.value.scale;

                        if (mapsegs[`${i},${j}`] === true) {

                            console.log("DEBUG SKIP");
                            continue;

                        }

                        if (j < 0 || i <0) {

                            continue;

                        }

                        const terrainInfo = await getTerrainInfo(i, j),
                          material = getTerrainMaterial(i, j),
                          geometry = new THREE.BufferGeometry(),
                          segment = new THREE.Mesh( geometry, material );
                          segment.castShadow = true;
                          segment.receiveShadow = true;

                        // Add neighbor pointers (utilized for stitching edges)
                        // if (j > 0) {

                            const prev = terrainNode.value.children[terrainNode.value.children.length - 1];
                            if (prev) {
                                terrainInfo.neighbors.north = prev;
                                prev.userData.terrainInfo.neighbors.south = segment;
                            }

                       // }

                       // if (i > 0) {

                            const prev_ = terrainNode.value.children[terrainNode.value.children.length - terrainData.value.mapSize * spg];
                            if (prev_) {
                                terrainInfo.neighbors.west = prev_;
                                prev_.userData.terrainInfo.neighbors.east = segment;
                            }

                       // }

                        segment.userData.terrainInfo = terrainInfo;

                        // by default 0,0 is the middle of the first grid
                        const grid = terrainData.value.lods[0] * spg;
                        // const centerOffsetX = (originGrid.value[0]/2 * grid) + (grid * terrainData.value.mapSize / 2) - grid / 2;
                        // const centerOffsetY = (originGrid.value[1]/2 * grid) + (grid * terrainData.value.mapSize / 2) - grid / 2;

                        //const centerOffsetX = (grid * terrainData.value.mapSize / 2) - grid / 2;
                        //const centerOffsetY = (grid * terrainData.value.mapSize / 2) - grid / 2;
                        //segment.position.set(x - centerOffsetX, y - centerOffsetY, terrainData.value.heightOffset);
                        //segment.position.set(x - centerOffsetX, y - centerOffsetY, terrainData.value.heightOffset);

                        //const centerOffsetX = baseX * terrainData.value.gridSize;
                        //const centerOffsetY = baseY * terrainData.value.gridSize;
                        //segment.position.set(x - centerOffsetX, y - centerOffsetY, terrainData.value.heightOffset);
                        //segment.position.set(x, y, terrainData.value.heightOffset);
                        segment.position.set(x, y, 0);
                        segment.position.sub(centermap);

                        terrainNode.value.add(segment);

                    }

                }

                // set terrain position and rotation
                terrainNode.value.position.set(
                  terrainData.value.position[0],
                  terrainData.value.position[1],
                  terrainData.value.position[2]);

                terrainNode.value.rotation.set(
                  THREE.MathUtils.degToRad(terrainData.value.rotation[0]),
                  THREE.MathUtils.degToRad(terrainData.value.rotation[1]),
                  THREE.MathUtils.degToRad(terrainData.value.rotation[2]));

                scene.add(toRaw(terrainNode.value));

                // do an initial update
                let {newTerrain:newTerrain, updateTerrain:updateTerrain} = updateLOD();

                worker.postMessage({
                    action: 'buildTerrainGeometry',
                    request: newTerrain
                });

        },
        loadTextures = async (callback) => {

            const textures = [

                // 0 diffuse
                [
                  //"public/textures/meadowgrass_diffuse_1k.jpg",
                  //"public/textures/grassmixdiffuse.png",
                  "public/textures/uvchecker.png",
                  //"public/textures/grassplain.png",
                  "public/textures/gravel_diffuse_1k.jpg",
                  "public/textures/moss_diffuse_1k.jpg",
                  "public/textures/rockydirt_diffuse_1k.jpg",
                  "public/textures/boulder_diffuse_1k.jpg",
                  "public/textures/rock1diffuse.png"
                ],
                // 1 normal
                [
                  //"public/textures/meadowgrass_normal_1k.jpg",
                  //"public/textures/grassmixnormal.png",
                  "public/textures/grassplainnormal.png",
                  "public/textures/gravel_normal_1k.jpg",
                  "public/textures/moss_normal_1k.jpg",
                  "public/textures/rockydirt_normal_1k.jpg",
                  "public/textures/boulder_normal_1k.jpg",
                  "public/textures/rock1normal.png"
                ],
                // roughness maps
                [
                  //"public/textures/meadowgrass_roughness_1k.jpg",
                  //"public/textures/grassmixdisp.png",
                  "public/textures/grassplainspec.png",
                  "public/textures/gravel_roughness_1k.jpg",
                  "public/textures/moss_roughness_1k.jpg",
                  "public/textures/rockydirt_roughness_1k.jpg",
                  "public/textures/boulder_roughness_1k.jpg",
                  "public/textures/rock1spec.png"
                ],
                // aomaps
                [
                  //"public/textures/meadowgrass_ao_1k.jpg",
                  "public/textures/grassplainao.png",
                  //"public/textures/grassyrocks_normal_1k.jpg",
                  //"public/textures/gravel_normal_1k.jpg",
                  "public/textures/moss_ao_1k.jpg",
                  //"public/textures/rockydirt_normal_1k.jpg",
                  //"public/textures/boulder_normal_1k.jpg"
                  "public/textures/rock1ao.png"
                ]

            ]

            for (let t = 0; t < textures.length; t++) {

                const dataArr = new Uint8Array(textures[t].length * 4 * 1024 * 1024);

                for (let i = 0; i < textures[t].length; i++) {

                    const {width,height,data} = await loadImage(textures[t][i]);
                    const offset = i * (4 * 1024 * 1024);

                    dataArr.set(data, offset);

                }

                //textureMaps[t] = new THREE.DataTexture2DArray(dataArr, 1024, 1024, textures[t].length);
                textureMaps[t] = new THREE.DataArrayTexture(dataArr, 1024, 1024, textures[t].length);

                if (t === 1) {

                    textureMaps[t].encoding = THREE.LinearEncoding;
                    textureMaps[t].format = THREE.RGBAFormat;
                    textureMaps[t].type = THREE.UnsignedByteType;
                    textureMaps[t].minFilter = THREE.LinearMipMapLinearFilter;
                    textureMaps[t].magFilter = THREE.LinearFilter;
                    textureMaps[t].wrapS = THREE.RepeatWrapping;
                    textureMaps[t].wrapT = THREE.RepeatWrapping;
                    //textureMaps[t].repeat.set(1, 1);
                    textureMaps[t].generateMipmaps = true;
                    textureMaps[t].needsUpdate = true;

                } else {

                    textureMaps[t].encoding = THREE.sRGBEncoding;
                    textureMaps[t].format = THREE.RGBAFormat;
                    textureMaps[t].type = THREE.UnsignedByteType;
                    textureMaps[t].minFilter = THREE.LinearMipMapLinearFilter;
                    textureMaps[t].magFilter = THREE.LinearFilter;
                    textureMaps[t].wrapS = THREE.RepeatWrapping;
                    textureMaps[t].wrapT = THREE.RepeatWrapping;
                    //textureMaps[t].repeat.set(1, 1);
                    textureMaps[t].generateMipmaps = true;
                    textureMaps[t].needsUpdate = true;
                }

            }

        },
        getTerrainMaterial = (x, y) => {

            var lowX, lowY;

            for (var i=0; i< terrainData.value.grids.length; i++) {

                lowX = lowX < terrainData.value.grids[i].id[0] ? lowX : terrainData.value.grids[i].id[0];
                lowY = lowY < terrainData.value.grids[i].id[1] ? lowY : terrainData.value.grids[i].id[1];

            }

            const spg = terrainData.value.gridSize / terrainData.value.lods[0];
            const gridX = Math.floor(x / spg) + lowX;
            const gridY = Math.floor(y / spg) + lowY;

            var i = 0
            for (var j=0; j< terrainData.value.grids.length; j++) {

                if (terrainData.value.grids[i][0] == lowX && terrainData.value.grids[i][1] == lowY) {

                    i = j;

                }

            }

            let shadowMapsLength = 0;
            let sun = scene.getObjectByName('sun');

            activeLights['sun'] = sun;

            const viewDirection = new THREE.Vector3();
            camera.value.camera.getWorldDirection(viewDirection);
            
           // shaderMaterial.glslVersion = THREE.GLSL3;

            const normalMap = textureLoader.load(terrainData.value.grids[i].normalmap);
            normalMap.colorSpace = THREE.LinearSRGBColorSpace;
            normalMap.minFilter = THREE.LinearMipMapLinearFilter;
            normalMap.magFilter = THREE.LinearFilter;
            //normalMap.wrapS = normalMap.wrapT = THREE.RepeatWrapping;

            const customMaterial = new THREE.MeshStandardMaterial({
              //  color: 0xffffff,
                roughness: 0.7,
                metalness: 0.1,
                //normalMap: normalMap
            });

            const uniforms = {

                normalMap: { value: normalMap },
                diffuseMaps: { value: textureMaps[0] },
                normalMaps: { value: textureMaps[1] },
                roughnessMaps: { value: textureMaps[2] },
                aoMaps: { value: textureMaps[3] },
                sunPosition: { value: sun.position.clone() },
                viewDirection: { value: viewDirection }

            }

            const custom_ = landscapeShader.build(customMaterial, uniforms);
            terrainMaterial.value = custom_;
            return customMaterial;

        },
        getTerrainInfo = async (x,y) => {

            var lowX, lowY;

            for (var i=0; i< terrainData.value.grids.length; i++) {

                lowX = lowX < terrainData.value.grids[i].id[0] ? lowX : terrainData.value.grids[i].id[0];
                lowY = lowY < terrainData.value.grids[i].id[1] ? lowY : terrainData.value.grids[i].id[1];

            }

            const spg = terrainData.value.gridSize / terrainData.value.lods[0];
            const gridX = Math.floor(x / spg) + lowX;
            const gridY = Math.floor(y / spg) + lowY;

            var i = 0;
            for (var j=0; j< terrainData.value.grids.length; j++) {

                if (terrainData.value.grids[j][0] == lowX && terrainData.value.grids[j][1] == lowY) {

                    i = j;

                }

            }

/*
            const texture = textureLoader.load(terrainData.value.grids[i].heightmap);
            texture.minFilter = THREE.NearestFilter;
            texture.colorSpace = THREE.SRGBColorSpace;
            // texture.generateMipmaps = false;
*/

            var height = [];
            var normal = [];
            var tangent = [];
            var bitangent = [];

            // find the x and y position in the grid heightmap
            var xpos = x%spg;
            var ypos = y%spg;

            let t = 0;
            let L = terrainData.value.lods[0];

            // two edge vertices are shared, so the mesh segments merge automatically
            // var start = ypos * L * (L * spg + 1) + (L * xpos);
            // var end = start + (L * spg + 1) * L + L;
            var start = ypos * L * (L * spg + 1) + (L * xpos);
            var end = start + L * (L * spg + 1) + L;

            // loop over each segment
            // +1 because we supply an image 1 pixel larger than grid size so grid verts match.

            if (!heightMaps.value[`${gridX},${gridY}`]) {

                console.error("Missing Heightmap For Grid " + `${gridX},${gridY}`)

            }

            for (var k=start; k <= end; k+=(L * spg + 1)) {

                // we provide one pixel more(+1) so that the segments share a horizontal height.
                // because of this we need to compensate the skew when generating geometry.

                const heightPart = heightMaps.value[`${gridX},${gridY}`]
                  .slice(k, k + L + 1);
                height.push(...heightPart);

/*
                // add precomputed normals 
                const normalPart = normalMaps.value[`${gridX},${gridY}`]
                  .slice(k*3, (k + L)*3 + 3);
                normal.push(...normalPart);
*/

            }

            return {

                coords:`${x  },${  y}`,
                grid:{x:gridX,y:gridY},
                lod:terrainData.value.lods.length - 1,
                lods:terrainData.value.lods,
                scale:terrainData.value.scale,
                heightmap:`${terrainData.value.grids[i].heightmap}`,
                height,
                normal,
                tangent,
                position:terrainData.value.grids[i].position,
                rotation:terrainData.value.grids[i].rotation,
                uvShift:{x:(x%spg)/spg,y:(y%spg)/spg,s:1/spg}, // how much we should adjust the UV
                neighbors: {} // n,e,s,w

            }

        },
        /*
         * This is the main update function that streams new terrain in, this works relatively well but there are issues
         *
         * - loading buffer geometry is kinda slow, but this needs to be done in the main thread, it is a limitation
         *   of web workers and gpu access. Right now we use the web worker to build the terrain data and then just generate
         *   the result in the main thread in a somewhat async way that tries not to block much.
         * - potentially we could move pass 1 lod update into the webworker too, it may say 30 odd ms every 3 seconds.
         * - TODO : we need to have some mechanism to save data and edit the mesh, so like some meshes could have custom 
         *   data, caves etc, for thos segments we could maybe save / load from threejs object format or use obj loader /
         *   exporter. Seams may be an issue though. Say you have 5 lods and 16 combinations of seams, it's going to be a
         *   problematic, how can we manage seams... maybe generate them separately ?
         *   Here is a possible solution
         *   - each LOD is basically a grid so you can change / store the x,y,z of custom vertices, the indices don't
         *     change, this can allow for overhangs, we just store each custom segment for each lod, the stitchs should
         *     behave the same as the vertex xy counts are the same.
         *   - For caves , caverns of any other more complex customizations, the developer needs to mark the vertices
         *     that are to be removed and supply custom models for each LOD that match, I'm unsure how stitches will
         *     work, but it will probably be problematic, the developer will need to supply models that can work in each
         *     situation, that can be somewhat complext.
         *   - The only other way would be to do away with stiches and find some other solution, meshlets or something.
         */
        updateLOD = () => {

            var startTime = performance.now();

            // first pass : calculate and set LOD for each segment.
            for (let i=0; i < terrainNode.value.children.length; i++) {

                const worldPosition = new THREE.Vector3();
                terrainNode.value.children[i].getWorldPosition(worldPosition);

                const d = worldPosition.distanceTo(camera.value.camera.position),
                  ti = terrainNode.value.children[i]?.userData?.terrainInfo;

                const prevLod = ti.lod;
                ti.prevLod = ti.lod;

                // Calculate LOD based on distance
                //if (d < ti.lods[0] * ti.scale * 2.0) {
                if (d < ti.lods[0] * ti.scale * 1.5) {

                    ti.lod = 0;

                } else if (d < ti.lods[0] * ti.scale * 2.5) {

                    ti.lod = 1;

                } else if (d < ti.lods[0] * ti.scale * 3.5) {

                    ti.lod = 2;

                } else if (d < ti.lods[0] * ti.scale * 4.5) {

                    ti.lod = 3;

                } else if (d < ti.lods[0] * ti.scale * 5.5) {

                    ti.lod = 4;

                } else {

                    ti.lod = 5;

                }

            }

            var endTime = performance.now()
            console.log(`update pass 1 took ${endTime - startTime} milliseconds`)

            var startTime = performance.now()

            // we batch the request data in here and pass it all to a worker thread at the end

            // segments that have a different lod
            const newTerrain = [];
            // segments that have the same lod but different edge
            const updateTerrain = [];

            // Second pass, render based on LOD
            for (let i=0; i < terrainNode.value.children.length; i++) {

                const ti = terrainNode.value.children[i].userData.terrainInfo,
                  c = terrainNode.value.children,
                  // Calculate edges based on neighbors (n,e,s,w)
                  edgeBounds = [
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.north?.userData?.terrainInfo?.lod),
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.east?.userData?.terrainInfo?.lod),
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.south?.userData?.terrainInfo?.lod),
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.west?.userData?.terrainInfo?.lod),
                  ];

                ti.debugData = {
                  "this": c[i].userData?.terrainInfo?.lod,
                  "north": c[i].userData?.terrainInfo?.neighbors?.north?.userData?.terrainInfo?.lod,
                  "east": c[i].userData?.terrainInfo?.neighbors?.east?.userData?.terrainInfo?.lod,
                  "south": c[i].userData?.terrainInfo?.neighbors?.south?.userData?.terrainInfo?.lod,
                  "west": c[i].userData?.terrainInfo?.neighbors?.west?.userData?.terrainInfo?.lod
                }

                ti.edgeBounds = ti.edgeBounds ?? [];

                // only update when necessary
                var newEdge = false;
                for (var j=0; j < edgeBounds.length; j++) {

                    if (edgeBounds[j] != ti.edgeBounds[j]) {

                        newEdge = true;

                    }

                }

                ti.edgeBounds = edgeBounds;

                // create new shared buffers for new LOD and reuse exisiting buffer for old LOD
                if (ti.prevLod != ti.lod) {

                    newTerrain.push([
                      ti.lods[0],
                      ti.lods[0],
                      ti.lods[ti.lod],
                      ti.lods[ti.lod],
                      edgeBounds,
                      c[i].userData?.terrainInfo?.coords,
                      toRaw(c[i].userData?.terrainInfo?.uvShift),
                      toRaw(c[i].userData?.terrainInfo?.height),
                      [],
                      [],
                      scale.value
                    ]);

                }

                if ((ti.prevLod === ti.lod) && (newEdge == true)) {

                    updateTerrain.push([
                      ti.lods[0],
                      ti.lods[0],
                      ti.lods[ti.lod],
                      ti.lods[ti.lod],
                      edgeBounds,
                      c[i].userData?.terrainInfo?.coords,
                      toRaw(c[i].userData?.terrainInfo?.uvShift),
                      toRaw(c[i].userData?.terrainInfo?.height),
                      [],
                      [],
                      scale.value
                    ]);

                }

            }

            var endTime = performance.now();
            console.log(`update pass 2 took ${endTime - startTime} milliseconds`)

            trackPosition.copy(camera.value.camera.position);

            return {newTerrain, updateTerrain};

        },
        trackCamera = () => {
        
            let d = trackPosition.distanceTo(camera.value.camera.position);

            const grid = getCurrentGrid();

            if (grid) {

/*
                if (!(grid[0] === currentGrid.value[0] && grid[1] === currentGrid.value[1])) {

console.log("getCurrentGrid() != currentGrid.value")
console.log("currentGrid.value")
console.log(currentGrid.value)
console.log("getCurrentGrid()")
console.log(getCurrentGrid())
                    currentGrid.value = getCurrentGrid();
                    buildMap();
*/
                //} else if (d > 100 && Object.keys(geometryQueue).length === 0) {

                if (d > 100 && Object.keys(geometryQueue).length === 0) {

                    trackPosition.copy(camera.value.camera.position);
                    let {newTerrain:newTerrain, updateTerrain:updateTerrain} = updateLOD();

                    worker.postMessage({
                        action: 'buildTerrainGeometry',
                        request: newTerrain
                    });

                    worker.postMessage({
                        action: 'updateTerrainGeometry',
                        request: updateTerrain
                    });

                }

            }

            setTimeout(()=> {

                trackCamera();

            }, 3000);

        },
        // Creates a worker to handle heavy updates
        startWorker = () => {

            worker.onmessage = (event) => {

                var { action, response } = event.data;
                activeSegments.value = [];

                // assign the new buffer and rebuild geometry
                if (action === "buildTerrainGeometryComplete") {

                    const threshold = 1024 * 1024;

                    console.log("response")
                    console.log(response)

                    for (var i in terrainNode.value.children) {

                        var id = terrainNode.value.children[i]?.userData?.terrainInfo?.coords;
                        var sharedAttributes = response[id];
                        if (sharedAttributes) {

                            buffers[id] = sharedAttributes;
                            geometryQueue[id] = {
                                action: 'build',
                                node: terrainNode.value.children[i]
                            }
                            // buildGeometry(sharedAttributes, terrainNode.value.children[i]);

                        }

                        if (id == currentGrid.value && setCameraPosition.value === true) {

                            setCameraPosition.value = false;
                            // camera.value.camera.position.copy(terrainNode.value.children[i].position);

                        }

                        const dx = terrainNode.value.children[i].position.x - camera.value.camera.position.x;
                        const dz = terrainNode.value.children[i].position.y - camera.value.camera.position.z;
                        const distSq = dx * dx + dz * dz;

                        terrainNode.value.children[i].visible = distSq <= threshold;

                        if (terrainNode.value.children[i].visible === true) {

                            activeSegments.value.push(id);

                        }

                    }
                    updateMacroMap();

                }

                // update geometry (buffer allready updated in worker)
                if (action === 'updateTerrainGeometryComplete') {

                    for (var i in terrainNode.value.children) {

                        var id = terrainNode.value.children[i]?.userData?.terrainInfo?.coords;
                        if (response[id] === true && buffers[id]) {

                            geometryQueue[id] = {
                                action: 'update',
                                node: terrainNode.value.children[i]
                            }
                            // updateGeometry(buffers[id], terrainNode.value.children[i]);

                        }

                        activeSegments.value.push(id);

                    }

                    if (terrainNode.value.children[i].visible === true) {

                        activeSegments.value.push(id);

                    }

                }

                if (action === 'getHeightMapComplete') {

                    if (heightMaps.value[`${response.gridX},${response.gridY}`] === undefined) {

                        heightMaps.value[`${response.gridX},${response.gridY}`] = response.heightMap;
                        decimateMaps.value[`${response.gridX},${response.gridY}`] = response.decimateMap;

                    }

                    // build when all maps loaded
                    if (Object.keys(heightMaps.value).length === terrainData.value.grids.length) {

                        buildMap();

                        trackCamera();

                    }

                }

                /*
                 * Get precomputed normals in addition to heights.
                 * Memory intensive, also calculation may not be very accurate
                 * Vertex calculation probably better.
                 */
                if (action === 'getHeightNormalMapComplete') {

                    if (heightMaps.value[`${response.gridX},${response.gridY}`] === undefined) {

                        heightMaps.value[`${response.gridX},${response.gridY}`] = response.heightMap;
                        normalMaps.value[`${response.gridX},${response.gridY}`] = response.normalMap;

                    }

                    // build when all maps loaded
                    if (Object.keys(heightMaps.value).length === terrainData.value.grids.length) {

                        //buildMap();

                    }

                }

            }

        },
        // build geometry 
        buildGeometry = async (attr, node, callback) => {

            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(attr.position, 3));
            geometry.setAttribute('normal', new THREE.Float32BufferAttribute(attr.normal, 3));
            geometry.setAttribute('tangent', new THREE.Float32BufferAttribute(attr.tangent, 3));
            geometry.setAttribute('bitangent', new THREE.Float32BufferAttribute(attr.bitangent, 3));
            geometry.setAttribute('uv', new THREE.Float32BufferAttribute(attr.uv, 2));
            geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(attr.uv2, 2));
            geometry.setIndex(new THREE.BufferAttribute(attr.indices, 1));
            // geometry.setIndex(attr.indices); // breaks , i think required basic array
            // geometry.computeVertexNormals(); // slow af , instead we do normal calc manually in worker
            geometry.attributes.position.needsUpdate = true;
            geometry.attributes.uv.needsUpdate = true;
            geometry.attributes.uv2.needsUpdate = true;
            geometry.attributes.normal.needsUpdate = true;
            geometry.attributes.tangent.needsUpdate = true;
            geometry.attributes.bitangent.needsUpdate = true;

            // if we use animation frame it seems to be fast but actually only one update per second..
            requestAnimationFrame(() => {
            });
            swapGeometry(node, toRaw(geometry))
            callback();

        },
        // update geometry in place
        updateGeometry = async (attr, node, callback) => {

            node.geometry.attributes.position.array.set(attr.position, 0);
            node.geometry.attributes.normal.array.set(attr.normal, 0);
            node.geometry.attributes.tangent.array.set(attr.tangent, 0);
            node.geometry.attributes.bitangent.array.set(attr.bitangent, 0);
            node.geometry.attributes.uv.array.set(attr.uv, 0);
            node.geometry.attributes.uv2.array.set(attr.uv2, 0);
            node.geometry.index.array.set(attr.indices, 0);
            node.geometry.attributes.position.needsUpdate = true;
            node.geometry.attributes.normal.needsUpdate = true;
            node.geometry.attributes.tangent.needsUpdate = true;
            node.geometry.attributes.bitangent.needsUpdate = true;
            node.geometry.index.needsUpdate = true;
            // node.geometry.computeVertexNormals(); // slow af

            callback();

        },
        swapGeometry = async (node, geometry) => {

            // clean up old geometry
            node.geometry.dispose();

            // Assign
            node.geometry = geometry;

            // Set Update TODO maybe push to worker also
            // node.geometry.attributes.position.needsUpdate = true;
            node.material.needsUpdate = true;

        },
        /*
         * coordinate updates in the main thread to minimize visual sheering
         * and manage render pipeline
         */
        processGeometryQueue = async () => {

            if (Object.keys(geometryQueue).length > 0 && inUpdate.value === false) {
    
                inUpdate.value = true;

                await mergeEdges();

                for (var i in geometryQueue) {

                    if (geometryQueue[i].action === 'update') {

                        updateGeometry(buffers[i], geometryQueue[i].node, function() {

                            delete geometryQueue[i];

                        })

                    } else if  (geometryQueue[i].action === 'build') {

                        buildGeometry(buffers[i], geometryQueue[i].node, function() {

                            delete geometryQueue[i];

                        });

                    }

                }

            } else {

                inUpdate.value = false;
                
                // final step is to fix the normal edges and update(notify) renderer

            }

            setTimeout(() => {

                processGeometryQueue();

            }, 300)

        },
        /*
         * This function runs at the end of the update pipeline, we loop over the whole map
         * and fix(average) the normals between neighbors. (makes it look seamless).
         */
        mergeEdges = () => {

            // run over all children, if the node below or to the right of current is in the queue
            // then we average normals between their edges in place in the buffer.
            for (let i=0; i < terrainNode.value.children.length; i++) {

                let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                let east = terrainInfo.neighbors.east?.userData?.terrainInfo;
                let south = terrainInfo.neighbors.south?.userData?.terrainInfo;

                if (buffers[terrainInfo.coords]) {

                    if (buffers[east?.coords]) {

                        var mergeN, mergeT, mergeLod;

                        // low lod int are higher fidelity
                        if (terrainInfo.lod > east.lod) {

                            mergeN = 'half';
                            mergeT = 'full';

                        } else if (terrainInfo.lod < east.lod) {

                            mergeN = 'full';
                            mergeT = 'half';

                        } else {

                            mergeN = 'full';
                            mergeT = 'full';

                        }

                        // load edge vertices for fast picking 
                        var Tpairs = mergePairs[terrainInfo.lod];
                        var Npairs = mergePairs[east.lod];

                        for (var j=0; j < Tpairs.horizontal.current[mergeT].length; j+=3) {

                            let Navg = normalize([
                                (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]]
                                + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]]
                                + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]]
                                + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]])/2
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]] = Navg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]] = Navg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]] = Navg[2];

                            // east node
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]] = Navg[0];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]] = Navg[1];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]] = Navg[2];

/*
                            // position , only necessary if smooting or other filters applied
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+1]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+1]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+2]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+2]];
*/

/*
                            let c = [
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]],
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]],
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]],
                            ]

                            let n = [
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]],
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]],
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]],
                            ]

                            const m1 = magnitude(c);
                            const m2 = magnitude(n);

                            const w1 = m1 / (m1+m2);
                            const w2 = m2 / (m1+m2);

                            const avg = normalize([
                                c[0] * w1 + n[0] * w2,
                                c[1] * w1 + n[1] * w2,
                                c[2] * w1 + n[2] * w2
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]] = avg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]] = avg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]] = avg[2];

                            // east node
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]] = avg[0];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]] = avg[1];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]] = avg[2];
*/
                        }

                    }

                }

            }

            for (let i=0; i < terrainNode.value.children.length; i++) {

                let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                let east = terrainInfo.neighbors.east?.userData?.terrainInfo;
                let south = terrainInfo.neighbors.south?.userData?.terrainInfo;

                if (buffers[terrainInfo.coords]) {

                    if (buffers[south?.coords]) {

                        // low lod int are higher fidelity
                        if (terrainInfo.lod > south.lod) {

                            mergeN = 'half';
                            mergeT = 'full';

                        } else if (terrainInfo.lod < south.lod) {

                            mergeN = 'full';
                            mergeT = 'half';

                        } else {

                            mergeN = 'full';
                            mergeT = 'full';

                        }

                        // load edge vertices for fast picking 
                        var Tpairs = mergePairs[terrainInfo.lod];
                        var Npairs = mergePairs[south.lod];

                        // stitch segments
                        for (var j=0; j < Tpairs.vertical.current[mergeT].length; j+=3) {

                            // this is unused, i tried several ways to blend, seems difficult to get without seams
                            let Navg = normalize([
                                (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]]
                                + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]]
                                + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                                + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]])/2
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = Navg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = Navg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = Navg[2];

                            // south node
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = Navg[0];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = Navg[1];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = Navg[2];

/*
                            // second way, maybe a little better?
                            let c = [
                              buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]],
                              buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]],
                              buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                            ]

                            let n = [
                              buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]],
                              buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]],
                              buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]]
                            ]

                            const m1 = magnitude(c); // sqrt of sum of each component squared
                            const m2 = magnitude(n); //

                            const w1 = m1 / (m1+m2);
                            const w2 = m2 / (m1+m2);

                            const avg = normalize([
                                c[0] * w2 + n[0] * w1,
                                c[1] * w2 + n[1] * w1,
                                c[2] * w2 + n[2] * w1
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = avg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = avg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = avg[2];

                            // east node
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = avg[0];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = avg[1];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = avg[2];
*/
/*
                            // position , only necessary if smooting or other filters applied
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+1]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+1]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+2]] 

                            // this node

                            // south node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]]
                              = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]];

                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]]
                              = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]];

                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                              = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]];

*/
/*
                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = Navg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = Navg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = Navg[2];

                            // south node
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = Navg[0];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = Navg[1];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = Navg[2];
*/
/*

                            // position
                            if ((j+1)%3 === 0) {

                                buffers[south.coords].position[Npairs.vertical.neighbor[mergeN][j]]
                                  = buffers[terrainInfo.coords].position[Tpairs.vertical.current[mergeT][j]];
                                
                            }
*/

                        }

                    }

                 }

              }

          },
          getCurrentGrid = () => {

              const gridSize = terrainData.value.gridSize;
              const cameraX = camera.value.camera.position.x;
              const cameraZ = camera.value.camera.position.z;

              // Adjust for center-based grid
              const adjustedX = cameraX + gridSize / 2;
              const adjustedZ = cameraZ + gridSize / 2;

              const xOffset = Math.floor(adjustedX / gridSize) + originGrid.value[0];
              const yOffset = Math.floor(adjustedZ / gridSize) + originGrid.value[1];

              //console.log("Camera Position:", cameraX, cameraZ);
              //console.log("Adjusted Position:", adjustedX, adjustedZ);
              //console.log("Grid Position:", xOffset, yOffset);

              if (!isNaN(xOffset) && !isNaN(yOffset)) {

                  return [xOffset, yOffset];

              } else {

                  return;

              }

          },
          getCurrentCoords = () => {

              const spg = terrainData.value.gridSize / terrainData.value.lods[0];
              const coord = terrainData.value.lods[0];
              const xOffset = Math.floor(camera.value.camera.position.x / coord);
              const yOffset = Math.floor(camera.value.camera.position.z / coord);

              const coordX = currentGrid.value[0] * spg + xOffset;
              const coordY = currentGrid.value[1] * spg + yOffset;

              return [coordX, coordY];

          },
          // TODO: write geometry to disk
          normalize = ([a,b,c]) => {

              let length = Math.sqrt( a*a + b*b + c*c );

              if (length === 0) 
                return [0, 0, 0];

              return [a / length, b / length, c / length];

          },
          magnitude = ([a,b,c]) => {

              return Math.sqrt( a*a + b*b + c*c );

          },
          toggleWireframe = () => {

              terrainNode.value.traverse((n) => {

                  if (n.material) {

                      if (n.material.wireframe == true) {

                          n.material.wireframe = false;
       
                      } else {

                          n.material.wireframe = true;
       
                      }

                  }

              });

          },
          // uses fast-png (supports 16bit greyscale)
          loadHeightMap = (imagePath) => {

              return new Promise(async (resolve, reject) => {

                  try {

                      const response = await axios.get(imagePath, { responseType: 'arraybuffer' });
                      const arrayBuffer = response.data;
                      console.log("load heightmap " + imagePath);
                      
                      const imageData = await decode(arrayBuffer);
                      //const imageData = jpeg.decode(new Uint8Array(arrayBuffer), { useTArray: true });
                      const { width, height, data } = imageData;

                      // console.log({ width, height, data });

                      resolve({ width, height, data });

                  } catch (error) {

                      reject(error);

                  }

              });

          },
          loadImage = (imagePath) => {

              return new Promise((resolve, reject) => {

                const image = new Image();

                image.onload = () => {

                    const width = image.width;
                    const height = image.height;

                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const context = canvas.getContext('2d');
                    context.drawImage(image, 0, 0);

                    const imageData = context.getImageData(0, 0, width, height);
                    const data = imageData.data;

                    const heights = [];

                    resolve({width, height, data});

                };

                image.onerror = function() {
            
                    console.error('Failed to load the image at ' + imagePath);
                    reject();

                };

                image.src = imagePath;

              })
          },
          // The supplied images from generate image have an extra duplicate pixel
          // on the right(east) and bottom(south) for consistent merge
          calculateMergePairs = () => {

              for (var lod in terrainData.value.lods) {

                  const pairs = { // pre-calculated lod neighbor segment vertex pairs for merging normals

                      // comparison of a node to it's neighbor to the east
                      horizontal: {
                          current: { // the current segment
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }, 
                          neighbor: { // the neighbor segment (right)
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }
                      },
                      vertical: {
                          current: { // the current segment
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }, 
                          neighbor: { // the neighbor segment (below)
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }
                      }

                  }; 

                  var verts = terrainData.value.lods[lod] + 1;

                  var f = 0;
                  // this (current) segment 
                  for (var j=verts - 1; j < verts*verts; j+=verts) {

                      pairs.horizontal.current.full.push(j*3);
                      pairs.horizontal.current.full.push(j*3+1);
                      pairs.horizontal.current.full.push(j*3+2);

                      if ((f/3) % 2 === 0) {

                          pairs.horizontal.current.half.push(j*3);
                          pairs.horizontal.current.half.push(j*3+1);
                          pairs.horizontal.current.half.push(j*3+2);

                      }

                      f+=3;

                  }

                  f = 0;
                  // neighbor (east) segment
                  for (var j=0; j < verts*verts; j+=verts) {

                      pairs.horizontal.neighbor.full.push(j*3);
                      pairs.horizontal.neighbor.full.push(j*3+1);
                      pairs.horizontal.neighbor.full.push(j*3+2);

                      if ((f/3) % 2 === 0) {

                          pairs.horizontal.neighbor.half.push(j*3);
                          pairs.horizontal.neighbor.half.push(j*3+1);
                          pairs.horizontal.neighbor.half.push(j*3+2);

                      }

                      f+=3;

                  }

                  f = 0;
                  // current
                  for (var i = verts * verts - verts; i < verts * verts; i++) {

                      pairs.vertical.current.full.push(i*3);
                      pairs.vertical.current.full.push(i*3+1);
                      pairs.vertical.current.full.push(i*3+2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.current.half.push(i*3);
                          pairs.vertical.current.half.push(i*3+1);
                          pairs.vertical.current.half.push(i*3+2);

                      }
                      f += 3;

                  }

                  f = 0;
                  // this one is the problem *** FIX
                  // neighbor (south) segment
                  for (var i = 0; i < verts; i++) {

                      pairs.vertical.neighbor.full.push(i*3);
                      pairs.vertical.neighbor.full.push(i*3+1);
                      pairs.vertical.neighbor.full.push(i*3+2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.neighbor.half.push(i*3);
                          pairs.vertical.neighbor.half.push(i*3+1);
                          pairs.vertical.neighbor.half.push(i*3+2);

                      }
                      f += 3;

                  }

/*
                  f = 0;
                  // this (current) segment 
                  for (var i = 0; i < verts; i++) {

                      pairs.vertical.current.full.push(i * 3);
                      pairs.vertical.current.full.push(i * 3 + 1);
                      pairs.vertical.current.full.push(i * 3 + 2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.current.half.push(i * 3);
                          pairs.vertical.current.half.push(i * 3 + 1);
                          pairs.vertical.current.half.push(i * 3 + 2);

                      }

                      f += 3;

                  }

                  f = 0;
                  // Neighbor (north) segment
                  for (var i = verts * verts - verts; i < verts * verts; i++) {

                      pairs.vertical.neighbor.full.push(i * 3);
                      pairs.vertical.neighbor.full.push(i * 3 + 1);
                      pairs.vertical.neighbor.full.push(i * 3 + 2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.neighbor.half.push(i * 3);
                          pairs.vertical.neighbor.half.push(i * 3 + 1);
                          pairs.vertical.neighbor.half.push(i * 3 + 2);

                      }

                      f += 3;

                  }
*/
                  mergePairs[lod] = pairs;

            }

        },
        process = () => {
        },
        buildMacroMap = () => {

            const textures = {
                heightMap: {
                    image: terrainData.value.miniHeightMap,
                    filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter }
                },
                normalMap: {
                    image: terrainData.value.miniNormalMap,
                    filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter },
                    colorSpace: THREE.LinearSRGBColorSpace
                },
                splatMap: {
                    image: terrainData.value.miniSplatMap,
                    filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter },
                    colorSpace: THREE.LinearSRGBColorSpace
                },
                flowMap: {
                    image: terrainData.value.miniFlowMap,
                    filter: { min: THREE.LinearMipMapLinearFilter, mag: THREE.LinearFilter },
                    colorSpace: THREE.LinearSRGBColorSpace
                }
            };

            const loadTexture = (data = {}) => {

                return new Promise((resolve, reject) => {

                    textureLoader.load(
                        data.image,
                        (texture) => {
                            if (data.filter) {
                                texture.minFilter = data.filter.min;
                                texture.magFilter = data.filter.mag;
                            }
                            if (data.colorSpace) {
                                texture.colorSpace = data.colorSpace;
                            }
                            resolve(texture);
                        },
                        undefined,  // No progress handler needed here
                        (error) => reject(error)  // Reject on error
                    );

                });

            };

            Promise.all([
                loadTexture(textures.heightMap),
                loadTexture(textures.normalMap),
                loadTexture(textures.splatMap),
                loadTexture(textures.flowMap)
            ]).then(([heightMap, normalMap, splatMap, flowMap]) => {

                const lodsize = terrainData.value.lods[0];
                const mapsize = terrainData.value.mapSize * terrainData.value.gridSize;
                const heightData = new Float32Array(lodsize * lodsize).fill(0); 

                //const geometry = new THREE.PlaneGeometry(mapsize, mapsize, lodsize - 1, lodsize - 1);
                const geometry = createPlaneGeometry(mapsize, mapsize, lodsize);
                //geometry.computeVertexNormals();

                updateCutout(geometry, lodsize, activeSegments.value);

                const material = new THREE.MeshStandardMaterial({
                    color: 0x00ff00,
                    displacementMap: heightMap,
               //     normalMap: normalMap,
                    displacementScale: terrainData.value.minimapHeightScale,
               //     displacementScale: 1,
                    wireframe: true
                });

//                const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });

                const minimapOffsetX = ((terrainData.value.mapSize+1)/2 - originGrid.value[0]+1) * terrainData.value.gridSize;
                const minimapOffsetY = ((terrainData.value.mapSize+1)/2 - originGrid.value[1]+1) * terrainData.value.gridSize;

                const macroTerrain = new THREE.Mesh(geometry, material);
                //macroTerrain.rotation.x = -Math.PI / 2;
                macroTerrain.position.set(0, terrainData.value.minimapHeightOffset, 0);
                macroTerrain.name = "macroTerrain";
                scene.add(macroTerrain);

            });

        },
        updateMacroMap = () => {

 //           const spg = terrainData.value.gridSize / terrainData.value.lods[0];
 //           const segNum = terrainData.value.mapSize * spg;
 //           const ss = 256 / segNum ;

            const lodsize = terrainData.value.lods[0];
            const mapsize = terrainData.value.mapSize * terrainData.value.gridSize;

            let macroTerrain = scene.getObjectByName("macroTerrain");
            let displacementTexture = macroTerrain.material.displacementMap;

            updateCutout(macroTerrain.geometry, lodsize, activeSegments.value);

            macroTerrain.geometry.dispose();
            macroTerrain.geometry = geometry;
            macroTerrain.material.needsUpdate = true;

        },
        createPlaneGeometry = (width, height, segments) => {

            const geometry = new THREE.BufferGeometry();

            // Generate vertices and UVs for the entire grid
            const vertices = [];
            const uvs = [];
            const normals = [];

            const segmentWidth = width / segments;
            const segmentHeight = height / segments;

            for (let i = 0; i <= segments; i++) {
                for (let j = 0; j <= segments; j++) {

                    const x = i * segmentWidth - width / 2;
                    const y = j * segmentHeight - height / 2;
                    vertices.push(x, 0, y);
                    uvs.push(i / segments, 1 - j / segments);
                    normals.push(0, 1, 0);

/*
                    const x = i * segmentWidth - width / 2;
                    const y = -(j * segmentHeight - height / 2); // Flip the y-axis
                    vertices.push(x, 0, y);
                    uvs.push(i / segments, j / segments); // Flip the V coordinate in UVs
                    //uvs.push(i / segments, 1 - j / segments);
*/
                }
            }

            // Set initial attributes
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
            geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
            geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));

            return geometry;
        },
        updateCutout = (geometry, segments, cutouts) => {

            console.log("UPDATE CUTOUT")
            console.log(cutouts)

            function isQuadInCutout(i, j, cutoutSet, stepSize) {

                const blockXStart = Math.floor(i / stepSize);
                const blockYStart = Math.floor(j / stepSize);
                const blockXEnd = Math.floor((i + 1) / stepSize);
                const blockYEnd = Math.floor((j + 1) / stepSize);

                for (let x = blockXStart; x <= blockXEnd; x++) {
                    for (let y = blockYStart; y <= blockYEnd; y++) {
                        if (cutoutSet.has(`${x},${y}`)) {
                            return true; // Quad overlaps with a cutout block
                        }
                    }
                }

            }

            const stepSize = 8;
            const cutoutSet = new Set(cutouts);
            const indices = [];

            for (let i = 0; i < segments; i++) {

                for (let j = 0; j < segments; j++) {

                    if (!isQuadInCutout(i, j, cutoutSet, stepSize)) {

                        const a = i * (segments + 1) + j;
                        const b = a + 1;
                        const c = a + (segments + 1);
                        const d = c + 1;

                        indices.push(a, b, d);
                        indices.push(a, d, c);

                    }

                }

            }

            geometry.setIndex(indices);

        };

        onMounted(() => {

            currentGrid.value = originGrid.value;

            loadTerrain();
            startWorker();
            processGeometryQueue();

            // set inital camera position
            //let x = currentPosition.value[0] * terrainData.value.gridSize;
            //let z = currentPosition.value[1] * terrainData.value.gridSize;
            //camera.value.camera.position = THREE.Vector3(x,0,z);
            trackPosition.copy(camera.value.camera.position);

            watch(
                () => keyPress.value,

                (first, second) => {

                    if (keyPress.value.o == true) {

                        toggleWireframe();

                    }

                },
            { deep: true });

        });

        onUnmounted(() => {
            worker.terminate();
            
            if (rootNode.value) {
                // rootNode.value.removeChild(renderer.domElement);
            }
        });

        return {
            scene,
            axios,
            rootNode,
            camera,
            trackPosition,
            trackCamera,
            updateDelta,
            debounceKey,
            loadTerrain,
            loadMaps,
            buildMap,
            updateLOD,
            terrainNode,
            buildMacroMap,
            updateMacroMap,
            cacheMap,
            terrainData,
            keyPress,
            toggleWireframe,
            worker,
            startWorker,
            buildGeometry,
            updateGeometry,
            processGeometryQueue,
            setCameraPosition,
            loadImage,
            swapGeometry,
            getTerrainInfo,
            getTerrainMaterial,
            terrainMaterial,
            mergeEdges,
            mergePairs,
            getCurrentCoords,
            getCurrentGrid,
            calculateMergePairs,
            currentGrid,
            originGrid,
            textureLoader,
            normalize,
            magnitude,
            geometryQueue,
            heightMaps,
            normalMaps,
            decimateMaps,
            textureMaps,
            loadTextures,
            loader,
            scale,
            activeLights,
            activeSegments,
            buffers,
            inUpdate,
            process,
            updateCutout,
            createPlaneGeometry
        };

    }

};

</script>

<style scoped>
div {
width: 100%;
height: 100%;
}
</style>
